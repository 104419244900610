<template>
  <v-container id="TipoDeVuelo" class="tipoDeVuelo">
    <section v-if="busqueda.destino === ''">
      <h2 class="text-center">Debes seleccionar un destino</h2>
      <div class="text-center">
        <v-btn :to="{ name: 'SeleccionaDestino' }">Seleccionar destino</v-btn>
      </div>
    </section>

    <section v-else>
      <CriteriosDeBusquedaAgrupados
        :tipoDeVuelo="false"
        :fechas="false"
        :aeronave="false"
        :pasajeros="false"
        :tipoDeServicio="false"
      />

      <h3 class="tipoDeVuelo__title">Selecciona tu tipo de vuelo</h3>
      <div class="d-flex justify-space-around">
        <div @click="tipoVuelo('Solo ida')">
          <BotonCuadradoTextoEIcono
            titulo="Solo ida"
            :iconos="[icons.purple.plane]"
          />
        </div>
        <div @click="tipoVuelo('Ida y vuelta')">
          <BotonCuadradoTextoEIcono titulo="Ida y vuelta" :iconos="[icons.purple.doublePlane]" />
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";
export default {
  components: {
    CriteriosDeBusquedaAgrupados,
    BotonCuadradoTextoEIcono,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("Buscador", ["busqueda"]),
    ...mapState(["icons"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    tipoVuelo(tipo) {
      this.SET_BUSQUEDA_PARAMS({ tipoDeVuelo: tipo });
      this.$router.push({ name: "SeleccionaFecha" });
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  },
};
</script>

<style lang="scss" scoped>
#TipoDeVuelo,
.tipoDeVuelo {
  @include buscadorMovilPaddingTop;
}
</style>