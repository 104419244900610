<template>
  <v-container fluid id="CriteriosBusqueda" class="criteriosBusqueda">
    <CriteriosDeBusquedaAgrupados />

    <div @click="buscar">
      <Boton
        :texto="
          busqueda.tipoDeViaje === 'Experiencias'
            ? 'Buscar experiencias disponibles'
            : 'Buscar vuelos disponibles'
        "
        :color="colors.mainPurple"
        width="100%"
        borderRadius="10px"
        height="53px"
        class="mt-8 mx-auto"
        :icono="icons.white.magnify"
        :deshabilitar="deshabilitarBoton"
      />
    </div>

    <router-link to="/" class="d-flex align-center align-self-start">
    <img :src="logos.hummingbird.estiradoNegro" alt="logoHB" width="65"> Volver a home
    </router-link>
  </v-container>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";

import { mapActions, mapState } from "vuex";
export default {
  name: "CriteriosBusqueda",
  components: {
    Boton,
    CriteriosDeBusquedaAgrupados,
  },
  computed: {
    ...mapState(["colors", "icons" , 'logos']),
    ...mapState("Buscador", ["busqueda"]),
    deshabilitarBoton() {
      // if (
      //   this.busqueda.origen === "" ||
      //   this.busqueda.destino === "" ||
      //   this.busqueda.fechas.ida === "" ||
      //   this.busqueda.aeronave.ida === "" ||
      //   this.busqueda.pasajeros < 1 ||
      //   this.busqueda.tipoDeVuelo === "" ||
      //   this.busqueda.tipoDeViaje === "" 
      //   // ||
      //   // this.busqueda.tipoDeServicio.ida === ""
      // )
      //   return true;
      // if (
      //   this.busqueda.tipoDeVuelo === "Ida y vuelta" &&
      //   (this.busqueda.aeronave.vuelta === "" ||
      //     this.busqueda.fechas.vuelta === "" 
      //     // ||
      //     // this.busqueda.tipoDeServicio.vuelta === ""
      //     )
      // )
      //   return true;
      // else return false;
      return false;
    },
  },
  methods: {
    ...mapActions("Buscador", ["reunirCriteriosYBuscar"]),
    buscar() {
      if (!this.deshabilitarBoton) {
        this.reunirCriteriosYBuscar();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#CriteriosBusqueda,
.criteriosBusqueda {
  @include buscadorMovilPaddingTop;
  padding-bottom: 100px;
  min-height: 100vh;
  &__cancelar {
    color: $main-red;
  }
}
</style>