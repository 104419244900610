<template>
  <div class="CardResultadoBusqueda d-flex align-center">
    <div class="pr-4 CardResultadoBusqueda__dot">
      <v-icon size="15">mdi-circle</v-icon>
    </div>
    <div class="CardResultadoBusqueda__info">
      <h1>{{infoResultado.destino}}</h1>
      <h5 class="CardResultadoBusqueda__info__ventas"><span class="font-weight-bold ">{{infoResultado.ventas}} personas</span> han volado a {{infoResultado.destino}}</h5>
    </div>
    <div class="ml-auto pt-1">
      <img :src="icons.purple.mapMarker" alt="">
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name: 'CardBusquedaDestino',
    props: ['infoResultado'],
    computed: {
        ...mapState(['colors' , 'icons'])
    },
};
</script>

<style lang="scss" scoped>
.CardResultadoBusqueda {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 17px;
  padding: 10px 16px;
  transition: box-shadow 0.3s ease;
  background-color: $main-white;
  &:hover {
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  &__info{
      &__ventas{
          opacity: .6;
      }
  }
  &:active{
    &__dot{
      color:lawngreen;
    }
  }
}
</style>