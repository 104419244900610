<template>
  <v-row
    no-gutters
    id="CriteriosAgrupados"
    class="criteriosAgrupados"
    justify="center"
  >
    <v-expansion-panels
      flat
      class="criteriosAgrupados__panelWrapper"
      v-model="panel"
    >
      <v-expansion-panel v-if="tipoDeViaje">
        <v-subheader class="pt-0">Tipo de viaje</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusqueda
              :titulo="busqueda.tipoDeViaje"
              :enlace="{ name: 'TipoDeViaje' }"
              :icono="[icons.purple.experiencias]"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetTipoDeViaje />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="origen">
        <v-subheader class="pt-0">Origen</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusqueda
              :titulo="busqueda.origen"
              :icono="[icons.purple.mapMarker]"
              :enlace="{ name: 'CiudadDeOrigen' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetOrigen />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="destino">
        <v-subheader class="pt-0">Destino de viaje</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusqueda
              :titulo="busqueda.destino"
              :icono="[icons.purple.mapMarker]"
              :enlace="{ name: 'SeleccionaDestino' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetDestino />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        v-if="tipoDeVuelo && busqueda.tipoDeViaje === 'Vuelos'"
      >
        <v-subheader class="tipoDeVuelo__subheader">Tipo de vuelo</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusqueda
              :titulo="busqueda.tipoDeVuelo"
              :icono="
                busqueda.tipoDeVuelo === 'Ida y vuelta'
                  ? [icons.purple.doublePlane]
                  : [icons.purple.plane]
              "
              :enlace="{ name: 'TipoDeVuelo' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetTipoDeVuelo />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="fechas">
        <v-subheader class="pt-0">Fecha de vuelo</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusquedaDoble
              :tituloIzq="
                busqueda.tipoDeViaje === 'Experiencias' ||
                busqueda.tipoDeVuelo === 'Solo ida'
                  ? 'Fecha de vuelo'
                  : 'Fecha de ida'
              "
              :subtituloIzq="busqueda.fechas.ida"
              tituloDer="Fecha de vuelta"
              :subtituloDer="busqueda.fechas.vuelta"
              :enlace="{ name: 'SeleccionaFecha' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetFechas />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="aeronave">
        <v-subheader class="pt-0">Tipo de aeronave</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusquedaDoble
              :tituloIzq="
                busqueda.aeronave.ida == busqueda.aeronave.vuelta ||
                busqueda.tipoDeViaje === 'Experiencias' ||
                busqueda.tipoDeVuelo === 'Solo ida'
                  ? 'Aeronave'
                  : 'Tipo de aeronave ida'
              "
              :subtituloIzq="busqueda.aeronave.ida"
              tituloDer="Tipo de aeronave vuelta"
              :subtituloDer="busqueda.aeronave.vuelta"
              :enlace="{ name: 'TipoDeAeronave' }"
              :iconos="iconosAeronave"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetAeronave />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="pasajeros">
        <v-subheader class="pt-0">Cantidad de pasajeros</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusqueda
              :titulo="
                busqueda.pasajeros > 1
                  ? `${busqueda.pasajeros} pasajeros`
                  : `${busqueda.pasajeros} pasajero`
              "
              :icono="[icons.purple.user]"
              :enlace="{ name: 'CantidadPasajeros' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="setPasajeros">
          <SetPasajeros />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="tipoDeServicio">
        <v-subheader class="pt-0">Tipo de servicio</v-subheader>
        <v-expansion-panel-header
          class="criteriosAgrupados__panel__header"
          hide-actions
        >
          <div>
            <CardCriterioBusquedaDoble
              :tituloIzq="
                busqueda.aeronave.ida == busqueda.aeronave.vuelta ||
                busqueda.tipoDeViaje === 'Experiencias' ||
                busqueda.tipoDeVuelo === 'Solo ida'
                  ? 'Tipo de servicio'
                  : 'Tipo de servicio ida'
              "
              :subtituloIzq="busqueda.tipoDeServicio.ida"
              tituloDer="Tipo de servicio vuelta"
              :subtituloDer="busqueda.tipoDeServicio.vuelta"
              :enlace="{ name: 'TipoDeServicio' }"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SetTipoServicio />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import SetOrigen from "@/components/Busqueda/SettersInputAgrupados/SetOrigen";
import SetDestino from "@/components/Busqueda/SettersInputAgrupados/SetDestino";
import SetTipoDeViaje from "@/components/Busqueda/SettersInputAgrupados/SetTipoDeViaje";
import SetTipoDeVuelo from "@/components/Busqueda/SettersInputAgrupados/SetTipoDeVuelo";
import SetFechas from "@/components/Busqueda/SettersInputAgrupados/SetFechas";
import SetAeronave from "@/components/Busqueda/SettersInputAgrupados/SetAeronave";
import SetPasajeros from "@/components/Busqueda/SettersInputAgrupados/SetPasajeros";
import SetTipoServicio from "@/components/Busqueda/SettersInputAgrupados/SetTipoServicio";
import CardCriterioBusqueda from "@/components/Busqueda/CardCriterioBusqueda";
import CardCriterioBusquedaDoble from "@/components/Busqueda/CardCriterioBusquedaDoble";
import { mapState } from "vuex";

export default {
  name: "CriteriosAgrupados",
  components: {
    SetOrigen,
    SetDestino,
    SetTipoDeViaje,
    SetTipoDeVuelo,
    SetFechas,
    SetAeronave,
    SetPasajeros,
    SetTipoServicio,
    CardCriterioBusqueda,
    CardCriterioBusquedaDoble,
  },
  props: {
    origen: {
      type: Boolean,
      default: true,
    },
    destino: {
      type: Boolean,
      default: true,
    },
    tipoDeViaje: {
      type: Boolean,
      default: true,
    },
    tipoDeVuelo: {
      type: Boolean,
      default: true,
    },
    fechas: {
      type: Boolean,
      default: true,
    },
    aeronave: {
      type: Boolean,
      default: true,
    },
    pasajeros: {
      type: Boolean,
      default: true,
    },
    tipoDeServicio: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      panel: [],
    };
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda", "panelActivo"]),
    iconosAeronave() {
      let aeronave = this.busqueda.aeronave.ida;
      let icono =
        aeronave === "Todo tipo de aeronave"
          ? [this.icons.purple.helicopterAndPlane]
          : aeronave === "Helicóptero"
          ? [this.icons.purple.helicopter.straight]
          : [this.icons.purple.plane];
      return icono;
    },
  },
  beforeUpdate() {
    this.panel = this.panelActivo;
  },
  mounted() {
    this.panel = this.panelActivo;
  },
};
</script>

<style lang="scss" scoped>
#CriteriosAgrupados,
.criteriosAgrupados {
  .v-subheader {
    color: $second-purple;
    font-size: 12px;
    height: fit-content;
    padding-top: 9px;
  }
  &__panelWrapper {
    z-index: 0;
    .v-expansion-panel {
      background: transparent !important;
    }
  }
  &__panel {
    &__header {
      background: transparent;
      padding: 10px 0;
    }
  }
  .setPasajeros {
    overflow-y: hidden;
  }
}
</style>