<template>
  <div>
    <v-subheader v-if="esIdaYVuelta">Ida</v-subheader>
    <div class="select d-flex justify-space-around">
      <div @click="setIda('Compartido')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Compartido"
          :iconos="[icons.purple.seat, icons.purple.seat]"
          :colorPunto="busqueda.tipoDeServicio.ida === 'Compartido' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
      <div @click="setIda('Charter')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Charter"
          :iconos="['', icons.purple.seat, '']"
          :colorPunto="busqueda.tipoDeServicio.ida === 'Charter' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
      <div @click="setIda('Flexible')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Flexible"
          :iconos="[icons.purple.seat, icons.purple.seat, icons.purple.seat]"
          :colorPunto="busqueda.tipoDeServicio.ida === 'Flexible' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
    </div>

    <v-subheader v-if="esIdaYVuelta">Vuelta</v-subheader>
    <div class="select d-flex justify-space-around" v-if="esIdaYVuelta">
      <div @click="setVuelta('Compartido')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Compartido"
          :iconos="[icons.purple.seat, icons.purple.seat]"
          :colorPunto="
            busqueda.tipoDeServicio.vuelta === 'Compartido' ? '#3CD52B' : '#C4C4C4'
          "
        />
      </div>
      <div @click="setVuelta('Charter')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Charter"
          :iconos="['', icons.purple.seat, '']"
          :colorPunto="busqueda.tipoDeServicio.vuelta === 'Charter' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
      <div @click="setVuelta('Flexible')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Flexible"
          :iconos="[icons.purple.seat, icons.purple.seat, icons.purple.seat]"
          :colorPunto="
            busqueda.tipoDeServicio.vuelta === 'Flexible' ? '#3CD52B' : '#C4C4C4'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";
import { mapState, mapMutations } from "vuex";

export default {
  name: "SetTipoDeServicio",
  components: {
    BotonCuadradoTextoEIcono,
  },
  data() {
    return {
      tipoServicioIda: "",
      tipoServicioVuelta: "",
    };
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? false
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true;
    },
    tipoServicioIdaYVuelta() {
      const tipoServicioSeleccionado = {
        ida: this.tipoServicioIda,
        vuelta: this.tipoServicioVuelta,
      };
      return tipoServicioSeleccionado;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarServicio(servicio) {
      await this.SET_BUSQUEDA_PARAMS({ tipoDeServicio: servicio });
    },
    setIda(servicio) {
      this.tipoServicioIda = servicio;

      if (
        !this.esIdaYVuelta ||
        (this.tipoServicioVuelta !== "" && this.esIdaYVuelta)
      ) {
        this.seleccionarServicio(this.tipoServicioIdaYVuelta);
      }
    },
    setVuelta(servicio) {
      this.tipoServicioVuelta = servicio;

      if (this.tipoServicioIda !== "") {
        this.seleccionarServicio(this.tipoServicioIdaYVuelta);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &__btn {
    height: 90px;
    width: 103px;
  }
}
.v-subheader{
  font-size: 14px;
}
</style>