<template>
  <div>
    <div
      v-for="(destino, i) in destinos"
      :key="i"
      @click="setDestino(destino.destino)"
    >
      <CardBusquedaDestino :infoResultado="destino" />
    </div>
  </div>
</template>

<script>
import CardBusquedaDestino from "@/components/Busqueda/CardBusquedaDestinoMovil";

import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {
    CardBusquedaDestino,
  },
  computed: {
    ...mapState("Buscador", ["busqueda"]),
    ...mapGetters("Experiencias", ["destinosExperienciasYVentas"]),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    destinos() {
      let vueloOExperiencia =
        this.busqueda.tipoDeViaje === "Vuelos"
          ? this.destinosVuelosYVentas
          : this.destinosExperienciasYVentas;

      let destinosFiltrados = vueloOExperiencia.filter((d) => {
        return d.destino != this.busqueda.destino;
      });

      return destinosFiltrados;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async setDestino(destinoVuelo) {
      await this.SET_BUSQUEDA_PARAMS({ destino: destinoVuelo });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>