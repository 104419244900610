<template>
  <div>
    <div class="CardResultadoBusqueda d-flex align-center">
      <div class="pr-4">
        <v-icon size="15" color="#3CD52B">mdi-circle</v-icon>
      </div>
      <div class="CardResultadoBusqueda__info">
        <h1>{{ titulo }}</h1>
        <h5 class="CardResultadoBusqueda__info__ventas pt-1" v-if="ventasDestinoSeleccionado">
          <span class="font-weight-bold"
            >{{ ventasDestinoSeleccionado.ventas }} personas</span
          >
          han volado a {{ ventasDestinoSeleccionado.destino }}
        </h5>
      </div>
      <div class="CardResultadoBusqueda__icons ml-auto pt-1 d-flex align-center">
        <img v-for="(icon , i ) in icono" :key="i" :src="icon" alt="icono" class="CardResultadoBusqueda__icons__icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState , mapGetters } from "vuex";

export default {
  name: "CardCriterioBusqueda",
  props: {
    titulo: String,
    icono: Array,
    enlace: Object    
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Buscador", ["destino" , 'busqueda']),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    ...mapGetters("Experiencias", ["destinosExperienciasYVentas"]),
    ventasDestinoSeleccionado(){
      let destino;
      if (this.busqueda.tipoDeViaje === 'Vuelos'){
        destino = this.destinosVuelosYVentas.find(destino => destino.destino === this.titulo) || false
      }
      else {
        destino = this.destinosExperienciasYVentas.find(destino => destino.destino === this.titulo) || false
      }
      return destino
    }
  },
};
</script>

<style lang="scss" scoped>
.CardResultadoBusqueda {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);;
  border-radius: 10px;
  padding: 10px 16px;
  transition: box-shadow 0.3s ease;
  background-color: $main-white;
  color: $main-gray;
  min-height: 63px;
  &:hover {
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.15);
  }
  &__info {
    h1{
        font-size: 15px;
        font-weight: 400;
    }
    &__ventas {
      opacity: 0.6;
    }
  }
  &__icons{
    &__icon{
      max-height: 30px;
    }
  }
}
</style>