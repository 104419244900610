import { render, staticRenderFns } from "./SetFechas.vue?vue&type=template&id=79560a9b&scoped=true&"
import script from "./SetFechas.vue?vue&type=script&lang=js&"
export * from "./SetFechas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79560a9b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
installComponents(component, {VDatePicker})
