<template>
  <div class="buttonsSlider py-4 text-center d-flex justify-lg-center">
    <v-btn
      class="buttonsSlider__btn mx-2"
      v-for="(n, i) in 10"
      :key="i"
      width="60"
      height="60"
      :color="busqueda.pasajeros === n ? colors.mainPurple : '#fff'"
      @click="seleccionarPasajeros(n)"
      :dark="busqueda.pasajeros === n ? true : false"
      >{{ n }}</v-btn
    >
    <div class="buttonsSlider__btn"></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "SetPasajeros",
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapState("Buscador", ["busqueda"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarPasajeros(pasajeros) {
      const pasajerosANumero = +pasajeros;
      await this.SET_BUSQUEDA_PARAMS({ pasajeros: pasajerosANumero });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonsSlider {
  overflow-y: hidden;
  touch-action: manipulation;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 74vw;
  &__btn {
    background-color: $main-white;
    &:last-child{
      padding-right: 10px;
    }
  }
}
</style>