<template>
  <div>
    <slot></slot>
    <div class="d-flex justify-space-around">
      <div @click="setTipoDeViaje('Vuelos')">
        <BotonCuadradoTextoEIcono
          titulo="Vuelos"
          width="90px"
          height="86px"
          :colorPunto="
            busqueda.tipoDeViaje === 'Vuelos'
              ? '#3CD52B'
              : '#C4C4C4'
          "
          :iconos="[icons.purple.vuelos]"
        />
      </div>
      <div @click="setTipoDeViaje('Experiencias')">
        <BotonCuadradoTextoEIcono
          titulo="Experiencias"
          width="90px"
          height="86px"
          :colorPunto="
            busqueda.tipoDeViaje === 'Experiencias'
              ? '#3CD52B'
              : '#C4C4C4'
          "
          :iconos="[icons.purple.experiencias]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";
import { mapMutations, mapState } from "vuex";
export default {
  name: "setTipoDeViaje",
  components: {
    BotonCuadradoTextoEIcono,
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async setTipoDeViaje(tipo) {
      await this.SET_BUSQUEDA_PARAMS({ tipoDeViaje: tipo });
    },
  },
  computed: {
    ...mapState(['icons']),
    ...mapState('Buscador' , ['busqueda'])
  },
};
</script>
<style lang="scss" scoped>
</style>
