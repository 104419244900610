<template>
  <v-container id="SeleccionaFecha" class="seleccionaFecha">
    <section>
      <CriteriosDeBusquedaAgrupados
        :fechas="false"
        :aeronave="false"
        :pasajeros="false"
        :tipoDeServicio="false"
      />

      <h3>Selecciona Fecha<span v-if="esIdaYVuelta">s</span></h3>

      <div @click="seleccionAutomaticaFechaVuelta">
        <v-date-picker
          class="seleccionaFecha__datePicker"
          :color="colors.mainPurple"
          locale="es-es"
          first-day-of-week="1"
          no-title
          :min="new Date().toISOString().slice(0, 10)"
          v-model="fechaSeleccionada"
          :range="esIdaYVuelta"
          :show-adjacent-months="true"
          width="100%"
          :show-current="false"
        ></v-date-picker>
      </div>
      <div class="text-center" @click="seleccionarFechas">
        <Boton
          texto="Continuar"
          :color="colors.mainPurple"
          width="100%"
          borderRadius="10px"
          :deshabilitar="deshabilitarBotonSiguiente"
        />
      </div>
    </section>
  </v-container>
</template>

<script>
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";
import Boton from "@/components/Botones/Boton";         
import { mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "SeleccionaFecha",
  components: {
    Boton,
    CriteriosDeBusquedaAgrupados,
  },
  data() {
    return {
      fechaSeleccionada: null,
      vueltaAutomatica: true,
    };
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === "Experiencias" ||
        this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true;
    },
    deshabilitarBotonSiguiente() {
      if (!this.esIdaYVuelta) {
        return this.fechaSeleccionada === null ? true : false;
      } else {
        return this.fechaSeleccionada === null ||
          this.fechaSeleccionada.length != 2
          ? true
          : false;
      }
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarFechas() {
      let fecha = { ida: "", vuelta: "" };
      if (typeof this.fechaSeleccionada === "object") {
        const fechaOrdenada = this.fechaSeleccionada.sort();
        fecha.ida = fechaOrdenada[0];
        fecha.vuelta = fechaOrdenada[1];
      } else {
        fecha.ida = this.fechaSeleccionada;
      }

      await this.SET_BUSQUEDA_PARAMS({ fechas: fecha });
      this.$router.push({ name: "TipoDeAeronave" });
    },
    seleccionAutomaticaFechaVuelta() {
      if (
        this.esIdaYVuelta &&
        this.vueltaAutomatica &&
        this.fechaSeleccionada.length === 1
      ) {
        const fechaIda = moment(this.fechaSeleccionada[0]);
        const formato = 'YYYY-MM-DD'
        this.fechaSeleccionada.push(fechaIda.add(7, "d").format(formato));
        this.vueltaAutomatica = false;
      }
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  },
};
</script>

<style lang="scss" scoped>
#SeleccionaFecha,
.seleccionaFecha {
  @include buscadorMovilPaddingTop;
  &__datePicker {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}
</style>