<template>
  <div class="seleccionaDestino">
    <header class="seleccionaDestino__header">
      <h1 class="seleccionaDestino__title text-center font-weight-bold">
        ¿Dónde quieres viajar?
      </h1>
      <div class="seleccionaDestino__search">
        <v-text-field
          label="Ingresa el destino a viajar"
          single-line
          solo
          class="rounded-xl seleccionaDestino__search__input"
          type="search"
          v-model="solicitudBusqueda"
          prepend-inner-icon="mdi-magnify"
          focus
          flat
        />
        <v-btn
          class="seleccionaDestino__search__btn rounded-r-xl rounded-l-0 elevation-0"
          color="transparent"
          light
          @click="solicitudBusqueda = ''"
        >
          Borrar
        </v-btn>
      </div>
    </header>
    <div v-if="resultadosBusqueda == 0 && solicitudBusqueda !== ''">
      <h2>No hay destinos que coincidan con tu busqueda</h2>
    </div>
    <div
      v-else
      v-for="(vuelo, i) in resultadosBusqueda"
      :key="i"
      @click="seleccionarDestino(vuelo.destino)"
    >
      <CardBusquedaDestino :infoResultado="vuelo" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import CardBusquedaDestino from "@/components/Busqueda/CardBusquedaDestinoMovil";
export default {
  components: {
    CardBusquedaDestino,
  },
  data() {
    return {
      solicitudBusqueda: "",
    };
  },
  computed: {
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    ...mapGetters("Experiencias", ["destinosExperienciasYVentas"]),
    ...mapState("Buscador", ["busquedaHomeMobile" , 'busqueda']),
    resultadosBusqueda() {
      let destinoEscrito = this.solicitudBusqueda.toLowerCase();
      let vueloOExperiencia = this.busqueda.tipoDeViaje === 'Vuelos' ? this.destinosVuelosYVentas : this.destinosExperienciasYVentas;
      let filtrarResultado = vueloOExperiencia.filter((vuelo) =>
        vuelo.destino.toLowerCase().includes(destinoEscrito)
      );

      return filtrarResultado;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["DESTINO_A_STORE", "BUSQUEDA_A_STORE" ,'SET_BUSQUEDA_PARAMS']),
    async seleccionarDestino(destinoVuelo) {
      await this.SET_BUSQUEDA_PARAMS({destino: destinoVuelo});

      this.busqueda.tipoDeViaje === 'Experiencias'
      ? this.$router.push({ name: "SeleccionaFecha" })
      : this.$router.push({ name: "TipoDeVuelo" });
    },
  },
  mounted() {
    this.solicitudBusqueda = this.busquedaHomeMobile;
  },
};
</script>

<style lang="scss" scoped>
.seleccionaDestino {
  color: $main-black;
  min-height: 100vh;
  padding-bottom: 157px;
  width: 100%;
  &__title {
    padding: 40px 0 20px;
    font-size: 1.125em;
  }
  &__search {
    position: relative;
    &__input {
      filter: drop-shadow(0px 9px 10px rgba(0, 0, 0, 0.15));
      input {
        background-color: #f00;
      }
    }
    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px !important;
      text-transform: initial;
    }
  }
}
</style>