<template>
  <div class="d-flex justify-space-around">
    <div @click="tipoVuelo('Solo ida')">
      <BotonCuadradoTextoEIcono
        titulo="Solo ida"
        :iconos="[icons.purple.plane]"
        width="90px"
        height="86px"
        :colorPunto="
          busqueda.tipoDeVuelo === 'Solo ida' ? '#3CD52B' : '#C4C4C4'
        "
      />
    </div>
    <div @click="tipoVuelo('Ida y vuelta')">
      <BotonCuadradoTextoEIcono
        titulo="Ida y vuelta"
        :iconos="[icons.purple.doublePlane]"
        width="90px"
        height="86px"
        :colorPunto="
          busqueda.tipoDeVuelo === 'Ida y vuelta' ? '#3CD52B' : '#C4C4C4'
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";

export default {
  name: "SetTipoDeVuelo",
  components: {
    BotonCuadradoTextoEIcono,
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState('Buscador',["busqueda"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    tipoVuelo(tipo) {
      this.SET_BUSQUEDA_PARAMS({ tipoDeVuelo: tipo });
    },
  },
};
</script>

<style lang="scss" scoped></style>