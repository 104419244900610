<template>
  <div>
    <v-subheader v-if="esIdaYVuelta">Ida</v-subheader>
    <div class="select d-flex justify-space-around">
      <div @click="setIda('Avión')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Avión"
          :iconos="[icons.purple.plane]"
          :colorPunto="tipoAeronaveIda === 'Avión' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
      <div @click="setIda('Helicóptero')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn px-1"
          titulo="Helicóptero"
          :iconos="[icons.purple.helicopter.straight]"
          :colorPunto="
            tipoAeronaveIda === 'Helicóptero' ? '#3CD52B' : '#C4C4C4'
          "
        />
      </div>
      <div @click="setIda('Todo tipo de aeronave')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Todo tipo de aeronave"
          :iconos="[icons.purple.helicopterAndPlane]"
          :colorPunto="
            tipoAeronaveIda === 'Todo tipo de aeronave' ? '#3CD52B' : '#C4C4C4'
          "
        />
      </div>
    </div>

    <v-subheader v-if="esIdaYVuelta">Vuelta</v-subheader>
    <div v-if="esIdaYVuelta" class="select d-flex justify-space-around">
      <div @click="setVuelta('Avión')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Avión"
          :iconos="[icons.purple.plane]"
          :colorPunto="tipoAeronaveVuelta === 'Avión' ? '#3CD52B' : '#C4C4C4'"
        />
      </div>
      <div @click="setVuelta('Helicóptero')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Helicóptero"
          :iconos="[icons.purple.helicopter.straight]"
          :colorPunto="
            tipoAeronaveVuelta === 'Helicóptero' ? '#3CD52B' : '#C4C4C4'
          "
        />
      </div>
      <div @click="setVuelta('Todo tipo de aeronave')">
        <BotonCuadradoTextoEIcono
          width="90px"
          height="86px"
          class="select__btn"
          titulo="Todo tipo de aeronave"
          :iconos="[icons.purple.helicopterAndPlane]"
          :colorPunto="
            tipoAeronaveVuelta === 'Todo tipo de aeronave'
              ? '#3CD52B'
              : '#C4C4C4'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";

export default {
  name: "SetAeronave",
  components: {
    BotonCuadradoTextoEIcono,
  },
  data() {
    return {
      tipoAeronaveIda: "",
      tipoAeronaveVuelta: "",
    };
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? false
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true;
    },
    aeronavesIdaYVuelta() {
      const aeronavesSeleccionadas = {
        ida: this.tipoAeronaveIda,
        vuelta: this.tipoAeronaveVuelta,
      };
      return aeronavesSeleccionadas;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarAeronave(aeronave) {
      await this.SET_BUSQUEDA_PARAMS({ aeronave: aeronave });
    },
    setIda(aeronave) {
      this.tipoAeronaveIda = aeronave;

      if (
        !this.esIdaYVuelta ||
        (this.tipoAeronaveVuelta !== "" && this.esIdaYVuelta)
      ) {
        this.seleccionarAeronave(this.aeronavesIdaYVuelta);
      }
    },
    setVuelta(aeronave) {
      this.tipoAeronaveVuelta = aeronave;

      if (this.tipoAeronaveIda !== "") {
        this.seleccionarAeronave(this.aeronavesIdaYVuelta);
      }
    },
  },
  mounted() {
    this.tipoAeronaveIda = this.busqueda.aeronave.ida;
    if (typeof this.aeronave === "object") {
      this.tipoAeronaveVuelta = this.busqueda.aeronave.vuelta;
    }
  },
};
</script>

<style lang="scss" scoped>

.v-subheader{
  font-size: 14px;
}
.select {
  &__btn {
    height: 82px;
    width: 86px;
  }
}
</style>