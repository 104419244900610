<template>
  <div>
    <div @click="seleccionAutomaticaFechaVuelta">
      <v-date-picker
        class="seleccionaFecha__datePicker"
        :color="colors.mainPurple"
        locale="es-es"
        first-day-of-week="1"
        no-title
        :min="new Date().toISOString().slice(0, 10)"
        v-model="fechaSeleccionada"
        :range="esIdaYVuelta"
        :show-adjacent-months="true"
        width="100%"
        :show-current="false"
      ></v-date-picker>
    </div>
    <div class="text-center" @click="seleccionarFechas">
      <Boton
        texto="Confirmar selección"
        :color="colors.mainPurple"
        width="100%"
        borderRadius="10px"
        :deshabilitar="deshabilitarBotonSiguiente"
      />
    </div>
  </div>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "SetFecha",
  components: {
    Boton,
  },
  data() {
    return {
      fechaSeleccionada: null,
      vueltaAutomatica: true,
    };
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === "Experiencias" ||
        this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true;
    },
    deshabilitarBotonSiguiente() {
      if (!this.esIdaYVuelta) {
        return this.fechaSeleccionada === null ? true : false;
      } else {
        return this.fechaSeleccionada === null ||
          this.fechaSeleccionada.length != 2
          ? true
          : false;
      }
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarFechas() {
      let fecha = { ida: "", vuelta: "" };
      if (typeof this.fechaSeleccionada === "object") {
        const fechaOrdenada = this.fechaSeleccionada.sort();
        fecha.ida = fechaOrdenada[0];
        fecha.vuelta = fechaOrdenada[1];
      } else {
        fecha.ida = this.fechaSeleccionada;
      }

      await this.SET_BUSQUEDA_PARAMS({ fechas: fecha });
    },
    seleccionAutomaticaFechaVuelta() {
      if (
        this.esIdaYVuelta &&
        this.vueltaAutomatica &&
        this.fechaSeleccionada.length === 1
      ) {
        const fechaIda = moment(this.fechaSeleccionada[0]);
        const formato = "YYYY-MM-DD";
        this.fechaSeleccionada.push(fechaIda.add(7, "d").format(formato));
        this.vueltaAutomatica = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>