<template>
  <div class="botonCuadrado" :style="{height: `${height}` , width: `${width}`}">
    <div class="botonCuadrado__title d-flex align-center">
      <v-icon :color="colorPunto" size="12">mdi-circle</v-icon>
      <h5>{{ titulo }}</h5>
    </div>
    <div
      class="botonCuadrado__icon d-flex justify-center align-center"
      v-if="iconos"
    >
      <div class="d-flex justify-center">
        <img v-for="(icono, i) in iconos" :key="i" :src="icono" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    titulo: String,
    iconos: Array,
    colorPunto: {
      type: String,
      default: "#C4C4C4",
    },
    width: {
      default: "90px",
    },
    height: {
      default: "90px",
    },
  },
  computed: {
    ...mapState(["colors"]),
  },
};
</script>

<style lang="scss" scoped>
.botonCuadrado {
  background-color: $main-white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 100%;
  padding: 5px;
  width: 100%;
  transition: box-shadow 0.3s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  &__title {
    h5 {
      font-size: 11px;
    }
  }
  &__icon {
    height: 75%;
    width: 100%;
    div {
      border-bottom: 2px dashed #34d82c;
      width: 45px;
      img {
        width: 100%;
        padding-bottom: 5px;
      }
    }
  }
}
</style>