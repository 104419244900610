<template>
  <v-container id="TipoDeViaje" class="tipoDeViaje">
    <section>
      <h3 class="tipoDeViaje__title">Vuelos o experiencias?</h3>
      <div class="d-flex justify-space-around">
        <div @click="setTipoDeViaje('Vuelos')">
          <BotonRadio titulo="Vuelos" :iconos="[icons.purple.vuelos]" />
        </div>
        <div @click="setTipoDeViaje('Experiencias')">
          <BotonRadio titulo="Experiencias" :iconos="[icons.purple.experiencias]" />
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import BotonRadio from "@/components/Busqueda/BotonCuadradoTextoEIcono";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    BotonRadio,
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async setTipoDeViaje(tipo) {
      await this.SET_BUSQUEDA_PARAMS({ tipoDeViaje: tipo });
      this.$router.push({ name: "CiudadDeOrigen" });
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  },
};
</script>

<style lang="scss" scoped>
.tipoDeViaje,
#TipoDeViaje {
  @include buscadorMovilPaddingTop;
}
</style>