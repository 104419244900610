<template>
  <v-container id="TipoDeServicio" class="tipoDeServicio">
    <section>
      <CriteriosDeBusquedaAgrupados :tipoDeServicio="false" />

      <h3>Tipo de servicio</h3>
      <v-subheader v-if="esIdaYVuelta">Ida</v-subheader>
      <div class="tipoDeServicio__select d-flex justify-space-around">
        <div @click="setIda('Compartido')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Compartido"
            :iconos="[icons.purple.seat,icons.purple.seat]"
            :colorPunto="
              tipoServicioIda === 'Compartido' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
        <div @click="setIda('Charter')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Charter"
            :iconos="['' , icons.purple.seat , '']"
            :colorPunto="tipoServicioIda === 'Charter' ? '#3CD52B' : '#C4C4C4'"
          />
        </div>
        <div @click="setIda('Flexible')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Flexible"
            :iconos="[icons.purple.seat,icons.purple.seat,icons.purple.seat]"
            :colorPunto="tipoServicioIda === 'Flexible' ? '#3CD52B' : '#C4C4C4'"
          />
        </div>
      </div>

      <v-subheader v-if="esIdaYVuelta">Vuelta</v-subheader>
      <div
        class="tipoDeServicio__select d-flex justify-space-around"
        v-if="esIdaYVuelta"
      >
        <div @click="setVuelta('Compartido')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Compartido"
            :iconos="[icons.purple.seat , icons.purple.seat]"
            :colorPunto="
              tipoServicioVuelta === 'Compartido' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
        <div @click="setVuelta('Charter')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Charter"
            :iconos="['' , icons.purple.seat , '']"
            :colorPunto="
              tipoServicioVuelta === 'Charter' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
        <div @click="setVuelta('Flexible')">
          <BotonCuadradoTextoEIcono
            class="tipoDeServicio__select__btn"
            titulo="Flexible"
            :iconos="[icons.purple.seat , icons.purple.seat, icons.purple.seat]"
            :colorPunto="
              tipoServicioVuelta === 'Flexible' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";

import { mapState, mapMutations } from "vuex";

export default {
  name: "TipoDeServicio",
  components: {
    BotonCuadradoTextoEIcono,
    CriteriosDeBusquedaAgrupados,
  },
  data() {
    return {
      tipoServicioIda: "",
      tipoServicioVuelta: "",
    };
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === 'Experiencias'
      ? false
      : this.busqueda.tipoDeVuelo === 'Solo ida'
      ? false
      : true;
    },
    tipoServicioIdaYVuelta() {
      const tipoServicioSeleccionado = {
        ida: this.tipoServicioIda,
        vuelta: this.tipoServicioVuelta,
      };
      return tipoServicioSeleccionado;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarServicio(servicio) {
      await this.SET_BUSQUEDA_PARAMS({ tipoDeServicio: servicio });
      this.$router.push({ name: "CriteriosBusqueda" });
    },
    setIda(servicio) {
      this.tipoServicioIda = servicio;

      if (
        !this.esIdaYVuelta ||
        (this.tipoServicioVuelta !== "" && this.esIdaYVuelta)
      ) {
        this.seleccionarServicio(this.tipoServicioIdaYVuelta);
      }
    },
    setVuelta(servicio) {
      this.tipoServicioVuelta = servicio;

      if (this.tipoServicioIda !== "") {
        this.seleccionarServicio(this.tipoServicioIdaYVuelta);
      }
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  },
};
</script>

<style lang="scss" scoped>
#TipoDeServicio,
.tipoDeServicio {
  @include buscadorMovilPaddingTop;
  &__select {
    &__btn {
      height: 90px;
      width: 103px;
    }
  }
}
</style>