<template>
  <v-container id="TipoDeAeronave" class="tipoDeAeronave mx-auto">
    <section>
      <CriteriosDeBusquedaAgrupados
        :aeronave="false"
        :pasajeros="false"
        :tipoDeServicio="false"
      />

      <h3>Tipo de aeronave</h3>
      <v-subheader v-if="esIdaYVuelta">Ida</v-subheader>
      <div class="tipoDeAeronave__select d-flex justify-space-around">
        <div @click="setIda('Avión')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Avión"
            :iconos="[icons.purple.plane]"
            :colorPunto="tipoAeronaveIda === 'Avión' ? '#3CD52B' : '#C4C4C4'"
          />
        </div>
        <div @click="setIda('Helicóptero')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Helicóptero"
            :iconos="[icons.purple.helicopter.straight]"
            :colorPunto="
              tipoAeronaveIda === 'Helicóptero' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
        <div @click="setIda('Todo tipo de aeronave')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Todo tipo de aeronave"
            :iconos="[icons.purple.helicopterAndPlane]"
            :colorPunto="
              tipoAeronaveIda === 'Todo tipo de aeronave'
                ? '#3CD52B'
                : '#C4C4C4'
            "
          />
        </div>
      </div>

      <v-subheader v-if="esIdaYVuelta">Vuelta</v-subheader>
      <div
        v-if="esIdaYVuelta"
        class="tipoDeAeronave__select d-flex justify-space-around"
      >
        <div @click="setVuelta('Avión')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Avión"
            :iconos="[icons.purple.plane]"
            :colorPunto="tipoAeronaveVuelta === 'Avión' ? '#3CD52B' : '#C4C4C4'"
          />
        </div>
        <div @click="setVuelta('Helicóptero')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Helicóptero"
            :iconos="[icons.purple.helicopter.straight]"
            :colorPunto="
              tipoAeronaveVuelta === 'Helicóptero' ? '#3CD52B' : '#C4C4C4'
            "
          />
        </div>
        <div @click="setVuelta('Todo tipo de aeronave')">
          <BotonCuadradoTextoEIcono
            class="tipoDeAeronave__select__btn"
            titulo="Todo tipo de aeronave"
            :iconos="[icons.purple.helicopterAndPlane]"
            :colorPunto="
              tipoAeronaveVuelta === 'Todo tipo de aeronave'
                ? '#3CD52B'
                : '#C4C4C4'
            "
          />
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import BotonCuadradoTextoEIcono from "@/components/Busqueda/BotonCuadradoTextoEIcono";
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";

export default {
  name: "TipoDeAeronave",
  components: {
    BotonCuadradoTextoEIcono,
    CriteriosDeBusquedaAgrupados,
  },
  data() {
    return {
      tipoAeronaveIda: "",
      tipoAeronaveVuelta: "",
    };
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Buscador", ["busqueda"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === 'Experiencias'
      ? false
      : this.busqueda.tipoDeVuelo === 'Solo ida'
      ? false
      : true;
    },
    aeronavesIdaYVuelta() {
      const aeronavesSeleccionadas = {
        ida: this.tipoAeronaveIda,
        vuelta: this.tipoAeronaveVuelta,
      };
      return aeronavesSeleccionadas;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarAeronave(aeronave) {
      await this.SET_BUSQUEDA_PARAMS({ aeronave: aeronave });
      this.$router.push({ name: "CantidadPasajeros" });
    },
    setIda(aeronave) {
      this.tipoAeronaveIda = aeronave;

      if (
        !this.esIdaYVuelta ||
        (this.tipoAeronaveVuelta !== "" && this.esIdaYVuelta)
      ) {
        this.seleccionarAeronave(this.aeronavesIdaYVuelta);
      }
    },
    setVuelta(aeronave) {
      this.tipoAeronaveVuelta = aeronave;

      if (this.tipoAeronaveIda !== "") {
        this.seleccionarAeronave(this.aeronavesIdaYVuelta);
      }
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );

    this.tipoAeronaveIda = this.busqueda.aeronave.ida;
    if (typeof this.aeronave === "object") {
      this.tipoAeronaveVuelta = this.busqueda.aeronave.vuelta;
    }
  },
};
</script>

<style lang="scss" scoped>
#TipoDeAeronave,
.tipoDeAeronave {
  @include buscadorMovilPaddingTop;
  &__select {
    &__btn {
      height: 89px;
      width: 104px;
    }
  }
}
</style>