<template>
  <div>
    <div
      v-for="(vuelo, i) in ciudadOrigen"
      :key="i"
      @click="setOrigen(vuelo.destino)"
    >
      <CardBusquedaDestino :infoResultado="vuelo" />
    </div>
  </div>
</template>

<script>
import CardBusquedaDestino from "@/components/Busqueda/CardBusquedaDestinoMovil";

import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {
    CardBusquedaDestino,
  },
  computed: {
    ...mapState("Buscador", ["busqueda"]),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    ciudadOrigen() {
      let origenesDistintosAlSeleccionado = this.destinosVuelosYVentas.filter(
        (d) => {
          return d.destino != this.busqueda.origen;
        }
      );

      return origenesDistintosAlSeleccionado;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async setOrigen(ciudad) {
      await this.SET_BUSQUEDA_PARAMS({ origen: ciudad });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>