<template>
  <footer id="Stepper" class="stepper d-flex justify-space-around align-center" >
    <router-link to="/">
      <img :src="logos.hummingbird.estiradoNegro" alt="logoHB" width="65">
    </router-link>
    <div class="stepper__dots" >
      <v-icon
        v-for="(paso, i) in pasos"
        :key="i"
        class="stepper__dots__dot px-1"
        :class="{active : i == childrenIndex }"
        :size="sizeDots"
        >mdi-circle
      </v-icon>
    </div>
    <div></div>
    <div></div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "StepperBusqueda",
  data() {
    return {
      sizeDots: 12,
    };
  },
  computed: {
    ...mapState(['logos']),
    childrenIndex() {
      let indexChildren = this.$route.meta.index;
      return indexChildren;
    },
    pasos() {
      const pasos = this.$router.options.routes[1].children;
      const longitud = pasos.length - 1

      return longitud;
    },
    
  },
};
</script>

<style lang="scss" scoped>
#Stepper,
.stepper {
  background-color: $gray-background;
  height: 75px;
  position: fixed;
  bottom: 83px;
  width: 100%;
  h3 {
    color: $main-red;
    font-weight: 700;
  }
  &__dots {
    &__dot {
      color: $second-white;
    }
    .active {
      color: $main-purple;
    }
  }
}
</style>