<template>
  <v-container id="CantidadPasajeros" class="cantidadPasajeros">
    <section>
      <CriteriosDeBusquedaAgrupados
        :pasajeros="false"
        :tipoDeServicio="false"
      />

      <h3>¿Cuántos viajan?</h3>
      <div class="cantidadPasajeros__buttonsSlider py-4 text-center d-flex">
        <v-btn
          class="cantidadPasajeros__buttonsSlider__btn mx-2"
          v-for="(n, i) in 10"
          :key="i"
          width="60"
          height="60"
          color="transparent"
          @click="seleccionarPasajeros(n)"
          >{{ n }}</v-btn
        >
      </div>
    </section>
  </v-container>
</template>

<script>
import CriteriosDeBusquedaAgrupados from "@/components/Busqueda/CriteriosDeBusquedaAgrupados";

import { mapMutations, mapState } from "vuex";
export default {
  components: {
    CriteriosDeBusquedaAgrupados,
  },
  computed: {
    ...mapState(["icons"]),
 
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarPasajeros(pasajeros) {
      const pasajerosANumero = +pasajeros;
      await this.SET_BUSQUEDA_PARAMS({ pasajeros: pasajerosANumero });
      this.$router.push({ name: "TipoDeServicio" });
    },
  },
  mounted() {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  },
};
</script>

<style lang="scss" scoped>
#CantidadPasajeros,
.cantidadPasajeros {
  @include buscadorMovilPaddingTop;
  &__buttonsSlider {
    overflow-y: hidden;
    touch-action: manipulation;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &__btn {
      background-color: $main-white;
    }
  }
}
</style>