<template>
  <div>
    <div class="CardResultadoBusquedaDoble d-flex align-center">
      <div class="pr-4">
        <v-icon size="15" color="#3CD52B">mdi-circle</v-icon>
      </div>

      <div
        class="CardResultadoBusquedaDoble__info d-flex align-center"
        :class="{
          'justify-space-between':
            busqueda.tipoDeVuelo === 'Ida y vuelta' || iconos,
        }"
      >
        <div>
          <h5 class="pb-2">{{ tituloIzq }}</h5>
          <span>{{ subtituloIzq }}</span>
        </div>

        <div v-if="!mostrarDoble">
          <v-img
            v-for="(icono, i) in iconos"
            :key="i"
            :src="icono"
            width="33"
          />
        </div>

        <v-divider vertical v-if="mostrarDoble" />

        <div v-if="mostrarDoble">
          <h5 class="pb-2">{{ tituloDer }}</h5>
          <span>{{ subtituloDer }}</span>
        </div>

        <div v-if="mostrarDoble"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CardCriterioBusquedaDoble",
  props: {
    tituloIzq: String,
    tituloDer: String,
    subtituloIzq: String,
    subtituloDer: String,
    enlace: Object,
    iconos: Array,
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Buscador", ["destino", "tipoDeVuelo", "busqueda"]),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    ventasDestinoSeleccionado() {
      let destino =
        this.destinosVuelosYVentas.find(
          (destino) => destino.destino === this.titulo
        ) || false;
      return destino;
    },
    mostrarDoble() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? false
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true && this.subtituloIzq !== this.subtituloDer;
    },
  },
};
</script>

<style lang="scss" scoped>
.CardResultadoBusquedaDoble {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 16px;
  transition: box-shadow 0.3s ease;
  color: $main-gray;
  background-color: $main-white;
  min-height: 63px;
  &:hover {
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.15);
  }
  &__info {
    width: 100%;
    h5 {
      font-size: 10px;
      font-weight: 400;
      line-height: 5px;
    }
    span {
      font-size: 13px;
    }
  }
}
</style>