<template>
  <div id="Buscar" class="buscar">
    <v-container class="mx-auto">
      <img
        class="buscar__backArrow"
        :src="icons.gray.backArrow"
        alt="go back"
        @click="$router.go(-1)"
      />
      <transition name="slide" mode="out-in">
        <router-view class="main" />
      </transition>
    </v-container>
    <Stepper v-if="!ocultarEnCriteriosDeBusqueda" />
  </div>
</template>

<script>
import Stepper from "@/components/Busqueda/Stepper";
import { mapState } from "vuex";
export default {
  components: {
    Stepper,
  },
  computed: {
    ...mapState(["icons"]),
    ocultarEnCriteriosDeBusqueda() {
      return this.$route.name === "CriteriosBusqueda";
    },
  },
};
</script>

<style lang="scss" scoped>
#Buscar,
.buscar {
  position: relative;
  min-height: 100vh;
  background-color: #f9f9f9;
  &__backArrow {
    position: absolute;
    top: 42px;
    left: 15px;
    padding-left: inherit;
    :hover {
      cursor: pointer;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s, opacity 1.3s;
}

.slide-enter {
  // transform: translateX(50vw);
  opacity: 0;
}
.slide-leave-to {
  transform: translateX(-100vw);
  opacity: 0;
}
</style>