<template>
  <v-container class="seleccionaDestino">
    <header class="seleccionaDestino__header">
      <h1 class="seleccionaDestino__title text-center font-weight-bold">
        ¿Desde dónde viajas?
      </h1>
      <div class="seleccionaDestino__search">
        <v-text-field
          label="Desde dónde quieres viajar"
          single-line
          solo
          class="rounded-xl seleccionaDestino__search__input"
          type="search"
          v-model="ciudadOrigen"
          prepend-inner-icon="mdi-magnify"
          focus
          flat
          name="origen"
          :color="colors.mainPurple"
        />
        <v-btn
          class="seleccionaDestino__search__btn rounded-r-xl rounded-l-0 elevation-0"
          color="transparent"
          light
          @click="ciudadOrigen = ''"
        >
          Borrar
        </v-btn>
      </div>
    </header>

    <div
      v-for="(vuelo, i) in resultadosBusqueda"
      :key="i"
      @click="setOrigen(vuelo.destino)"
    >
      <CardBusquedaDestino :infoResultado="vuelo" />
    </div>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import CardBusquedaDestino from "@/components/Busqueda/CardBusquedaDestinoMovil";
export default {
  name: "SeleccionaOrigen",
  components: {
    CardBusquedaDestino,
  },
  data() {
    return {
      ciudadOrigen: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Vuelos", ["destinosVuelosYVentas"]),
    resultadosBusqueda() {
      let destinoEscrito = this.ciudadOrigen.toLowerCase();

      let filtrarResultado = this.destinosVuelosYVentas.filter((vuelo) =>
        vuelo.destino.toLowerCase().includes(destinoEscrito)
      );

      return filtrarResultado;
    },
  },

  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS" , 'CAMBIAR_PANEL_ACTIVO']),
    async setOrigen(ciudad) {
      await this.SET_BUSQUEDA_PARAMS({ origen: ciudad });
      await this.CAMBIAR_PANEL_ACTIVO(1)
      this.$router.push({ name: "SeleccionaDestino" });
    },
  },
};
</script>

<style lang="scss" scoped>
.seleccionaDestino {
  color: $main-black;
  min-height: 100vh;
  padding-bottom: 157px;
  &__title {
    padding: 40px 0 20px;
    font-size: 1.125em;
  }
  &__search {
    position: relative;
    &__input {
      filter: drop-shadow(0px 9px 10px rgba(0, 0, 0, 0.15));
      input {
        background-color: #f00;
      }
    }
    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px !important;
      text-transform: initial;
    }
  }
}
</style>